@import '../../styles/Variables';
@import '../../styles/Mixins';

.unauthorized-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-header {
    .button-primary {
      color: $white;
      background-color: $brightGreen;
    }
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
  }
}
