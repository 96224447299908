@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700&display=swap');

@import '../../node_modules/bootstrap/scss/bootstrap.scss';

@import './Variables';
@import './Mixins';
@import './Inputs';
@import './Pods';

html,
body {
  width: 100%;
  margin: 0px;
  height: 100%;
  padding: 0px;
}

* {
  outline: none !important;
  text-decoration: none !important;
}

body {
  color: $darkBlack;
  background-color: $lightSnow;
  font-family: 'Source Sans Pro', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

.page-container {
  padding-top: 60px;
  padding-left: 175px;

  &--nopad {
    padding-top: initial;
    padding-left: initial;
  }
}

.rows-replicable {
  margin-bottom: 24px;

  &-item {
    margin-bottom: 8px;

    .button-link {
      word-wrap: none;
      white-space: nowrap;
    }
  }

  &-trigger {
    .button-link {
      padding: 0px;
    }
  }

  .input-wrapper {
    margin-right: 16px;
    margin-bottom: 0px;
  }
}