@import '../../styles/Variables';
@import '../../styles/Mixins';

.table-loader {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);

  &-wrapper {
    text-align: center;
  }

  &-image {
    width: 180px;
    height: 180px;
  }

  &-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: $darkBlack;
  }
}

.dynamic-table {
  min-height: calc(100vh - 208px);

  .button-link {
    padding: 0px;
    border: none;
  }

  .vertical-separator {
    width: 1px;
    height: 10px;
    margin: 0px 8px;
    display: inline-block;
    background-color: #646464;
  }
}
