@import "../../styles/Variables";
@import "../../styles/Mixins";

.edituserproject-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
    margin-bottom: 32px;
  }

  hr {
    margin: 16px 0px;
    margin-bottom: 24px;
  }

  &-metadata {
    max-width: 50%;

    h5 {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: $darkBlack;
      margin-bottom: 24px;
    }
  }

  
}

.edituserproject-attachments {
  display: grid;
  padding: 16px 0px 0px 8px;

  a {
    margin-bottom: 16px;
  }
}
