@import '../../styles/Variables';
@import '../../styles/Mixins';

.matching-format {
  &-column {
    flex-grow: 1;
    margin-right: 16px;

    &:last-child {
      margin-right: 0px;
    }
  }
}
