// Define Global Variables
$black: #242424;
$white: #ffffff;
$grey: #8f8f8f;

// Define Theme Colors
$lightSnow: #ececec;
$darkSnow: #bfbfbf;

$darkBlack: #2e3138;
$lightBlack: #6c6f7d;

$brightGreen: rgba(31, 174, 15, 1);
$baseBlue: rgba(23, 35, 67, 1);
