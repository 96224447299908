@import '../../styles/Variables';
@import '../../styles/Mixins';

.login-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    max-width: 350px;
    margin: 0px auto;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.085);
  }

  &-brand {
    width: 64px;
    margin: 0px auto;
    margin-bottom: 32px;
  }

  &-header {
    text-align: center;
    margin-bottom: 32px;

    h4 {
      font-size: 20px;
      color: $baseBlue;
      font-weight: 600;
      line-height: 26px;
    }
  }

  &-disclaimer {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    color: $lightBlack;
    text-align: justify;
  }
}
