@import '../../styles/Variables';
@import '../../styles/Mixins';

.sessions-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-header {
    margin-bottom: 32px;

    .button-primary {
      color: $white;
      background-color: $brightGreen;
    }
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
  }

  .users-pod {
    padding: 16px;
    margin-bottom: 24px;
    border: 1px solid rgba(0, 0, 0, 0.085);
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.085);

    &-value {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
      color: $darkBlack;
      margin-bottom: 8px;
    }

    &-param {
      font-size: 18px;
      line-height: 24px;
      color: $lightBlack;
      // margin-bottom: 0px;
    }
  }
}
