@import '../../styles/Variables';
@import '../../styles/Mixins';

.page-sidebar {
  top: 60px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  position: fixed;
  overflow-y: auto;
  max-width: 175px;
  padding: 24px 8px;
  background-color: $baseBlue;

  &-options {
    padding: 0px;
    display: flex;
    margin-bottom: 0px;
    list-style-type: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.sidebar-item {
  width: 100%;
  padding-left: 16px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.085);

  &:last-child {
    border-bottom: 0px;
  }

  svg {
    line-height: 1;
    font-size: 14px;
    margin-right: 8px;
    width: 20px !important;
    display: inline-block;
  }

  a {
    font-size: 14px;
    color: $white;
    display: flex;
    padding: 8px 0px;
    align-items: center;
    justify-content: flex-start;

    &:hover,
    &:active,
    &:focus {
      color: $brightGreen;
    }
  }
}
