@import '../../styles/Variables';
@import '../../styles/Mixins';

.page-header {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 60px;
  z-index: 999;
  display: flex;
  position: fixed;
  padding: 8px 16px;
  align-items: center;
  background-color: $white;
  justify-content: space-between;
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.085);
}

.page-branding {
  &-logo {
    width: 44px;
    margin-right: 16px;
  }

  &-text {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: $baseBlue;
  }
}
