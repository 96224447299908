@import "../../styles/Variables";
@import "../../styles/Mixins";

.addnews-container {
  width: 100%;
  padding: 24px 16px;

  h5 {
    font-size: 16px;
    margin-top: 16px;
    font-weight: 600;
    color: $darkBlack;
  }

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
    margin-bottom: 32px;
  }

  &-content {
    max-width: 75%;
  }

  &-details {
    margin-bottom: 32px;

    p {
      margin-bottom: 12px;
    }

    a {
      display: block;
      font-weight: 600;
      margin-bottom: 32px;
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      color: $darkBlack;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 18px;
      font-weight: 600;
      color: $darkBlack;
      margin-bottom: 20px;
    }
  }

  .button-secondary {
    margin-left: 16px;
  }
}

.replicable-products {
  margin-bottom: 32px;

  &-item {
    padding: 16px;
    border-radius: 5px;
    margin-bottom: 16px;
    border: 1px solid $grey;
  }
}

.news-description {
  .description-replicable {
    margin-bottom: 24px;

    &-item {
      margin-bottom: 8px;

      .button-link {
        word-wrap: none;
        margin-top: 1.5rem;
        white-space: nowrap;
      }
    }

    &-trigger {
      .button-link {
        padding: 0px;
      }
    }

    .input-wrapper {
      margin-right: 16px;
      margin-bottom: 0px;
    }
  }
}