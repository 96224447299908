@import "../../styles/Variables";
@import "../../styles/Mixins";

.addcontent-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
    margin-bottom: 32px;
  }

  hr {
    margin: 16px 0px;
    margin-bottom: 24px;
  }

  &-metadata {
    max-width: 50%;

    h5 {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: $darkBlack;
      margin-bottom: 24px;
    }
  }
}

.project-description {
  .description-replicable {
    margin-bottom: 24px;

    &-item {
      margin-bottom: 8px;

      .button-link {
        word-wrap: none;
        white-space: nowrap;
      }
    }

    &-trigger {
      .button-link {
        padding: 0px;
      }
    }

    .input-wrapper {
      margin-right: 16px;
      margin-bottom: 0px;
    }
  }
}
