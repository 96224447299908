@import '../../styles/Variables';
@import '../../styles/Mixins';

.tabular-format {
  margin-bottom: 8px;
  
  &-row {
    width: 100%;
    border: 1px solid $lightSnow;
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid $lightSnow !important;
    }
  }

  &-cell {
    padding: 8px;
    flex-grow: 1;
    border-right: 1px solid $lightSnow;

    .input-wrapper {
      margin-right: 8px;
      margin-bottom: 0px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
