.content-context {
  h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $darkBlack;
    margin-bottom: 24px;
  }

  &-actions {
    padding: 16px;
    margin-bottom: 24px;
    background-color: $lightSnow;

    .input-wrapper {
      margin-bottom: 0px;
      margin-right: 16px;

      &:last-child {
        margin-right: 0px;
      }
    }

    .button-link {
      word-wrap: none;
      white-space: nowrap;
    }
  }
}

.content-question {
  padding: 16px;
  margin-bottom: 32px;
  border: 1px solid rgba(0, 0, 0, 0.085);

  &-header {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.085);
  }

  .question-replicable {
    margin-bottom: 24px;

    &-item {
      margin-bottom: 8px;

      .button-link {
        word-wrap: none;
        white-space: nowrap;
      }
    }

    &-trigger {
      .button-link {
        padding: 0px;
      }
    }

    .input-wrapper {
      margin-right: 16px;
      margin-bottom: 0px;
    }
  }

  .question-media {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    justify-content: flex-start;

    .input-wrapper {
      flex-grow: 1;
      margin-bottom: 0px;
      
      &:last-child {
        margin-right: 0px;
      }
    }

    .input-select {
      margin-right: 16px;
    }
  }
}
