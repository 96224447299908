@import '../../styles/Variables';
@import '../../styles/Mixins';

.editsession-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
    margin-bottom: 32px;
  }

  &-content {
    max-width: 50%;

    img {
      width: 300px;
      max-height: 200px;
    }

    .react-datepicker-wrapper {
      display: block;
    }
  }
}
