@import "../../styles/Variables";
@import "../../styles/Mixins";

.searchworksheets-container {
  width: 100%;
  padding: 24px 16px;

  &-wrapper {
    padding: 16px;
    background-color: $white;
  }

  &-header {
    margin-bottom: 32px;
  }

  &-title {
    font-size: 22px;
    font-weight: 600;
    color: $darkBlack;
  }

  .input-field {
    margin-right: 16px;
  }
}

.searchworksheets-body {
  &-item {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid rgba(0, 0, 0, 0.085);

    .search-text {
      font-size: 14px;
      line-height: 18px;
    }

    h5 {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }

    .button-secondary {
      color: $white;
      background-color: $baseBlue;
    }
  }

  .search-left {
    margin-right: 8px;
  }

  .search-right {
    margin-left: 8px;
  }
}
