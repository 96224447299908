.input-wrapper {
  width: 100%;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  &-inline {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .input-label {
      margin-bottom: 0px;
    }
  }
}

.input-mobile {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .input-select {
    max-width: 100px;
    margin-right: 16px;
  }
}

.input-label {
  font-size: 14px;
  line-height: 18px;
  width: fit-content;
  margin-bottom: 4px;
  color: $lightBlack;
}

.input-field {
  width: 100%;
  font-size: 14px;
  color: $lightBlack;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.065);

  &:active,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}

.input-select {
  width: 100%;
  height: 39px;
  font-size: 14px;
  color: $lightBlack;
  border: 1px solid rgba(0, 0, 0, 0.065);
  border-radius: 0px;

  &:active,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}

.input-checkbox {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 1px solid rgba(0, 0, 0, 0.065);
  border-radius: 0px;

  &:active,
  &:focus {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
}

.button {
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  line-height: 18px;
  color: $darkBlack;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.065);

  &:disabled {
    color: $lightBlack;
  }

  &-primary {
    color: $white;
    background-color: $brightGreen;
  }

  &-secondary {
    color: $white;
    background-color: $baseBlue;
  }

  &-link {
    border: 0px;
  }

  &-border {
    border: 1px solid;
  }
}

.error-wrapper {
  padding: 16px;
  margin-bottom: 24px;
  background-color: rgba(195, 85, 85, 1);

  h6 {
    color: $white;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
  }

  p {
    color: $white;
    font-size: 14px;
    font-weight: 400;
  }
}

.success-wrapper {
  padding: 16px;
  margin-bottom: 24px;
  background-color: $brightGreen;

  h6 {
    color: $white;
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 8px;
  }

  p {
    color: $white;
    font-size: 14px;
    font-weight: 400;
  }
}
