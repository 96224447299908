@import '../../styles/Variables';
@import '../../styles/Mixins';

.image-uploader {
  top: 84px;
  z-index: 666;
  padding: 16px;
  position: sticky;
  margin-bottom: 24px;
  background-color: $white;
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.15);

  &-header {
    margin-bottom: 24px;
  }

  &-title {
    font-size: 20px;
    font-weight: 600;
    color: $baseBlue;
    line-height: 26px;
    margin-bottom: 4px;
  }

  &-description {
    font-size: 16px;
    font-weight: 400;
    color: $lightBlack;
  }

  .upload-area,
  .display-area {
    width: 100%;

    &-label {
      flex: 1;
      padding: 16px;
      cursor: pointer;
      user-select: none;
      margin-right: 24px;
      margin-bottom: 0px;
      border-radius: 10px;
      border: 2px dashed $darkSnow;

      h6 {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $lightBlack;
      }
    }

    &-input {
      width: 1px;
      opacity: 0;
      height: 1px;
      top: -9999px;
      left: -9999px;
      position: absolute;
    }

    &-trigger {
      color: $white;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      padding: 8px 12px;
      border: 1px solid $brightGreen;
      background-color: $brightGreen;
    }
  }

  .display-area {
    &-label {
      cursor: text;
      user-select: initial !important;

      h6 {
        font-weight: 600;
      }
    }
  }
}
